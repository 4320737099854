<template>
<span>{{ rs }}.<small>{{ ts }}</small></span>
</template>

<script>
export default {
    name: 'cur',
    props: {
        value: { type: [String, Number], required: true }
    },
    data () {
        return {
            rs: '0',
            ts: '0'
        };
    },
    watch: {
        'value' () {
            this.setupValue();
        }
    },
    created () {
        this.setupValue();
    },
    methods: {
        setupValue () {
            const value = this.value + '';
            const iValue = parseInt(value, 10);
            this.rs = Math.floor(iValue / 100);
            let ts = iValue % 100;
            if (ts === 0) {
                ts = '00';
            } else if (ts < 10) {
                ts = '0' + ts;
            }
            this.ts = ts;
        }
    }
};
</script>

<style scoped>

</style>
